import { application } from "./application";

import { CheckoutFormController } from "./payment/checkout_form_controller";
import { DatePickerFormController } from "./form_helpers/date_picker_form_controller";
import { LeadScanGridController } from "./grids/lead_scan_grid_controller";
import { ReadOnlyGridController } from "./grids/read_only_grid_controller";
import { RegFormController } from "./form_helpers/reg_form_controller";
import { RoomingFieldController } from "./rooming/rooming_field_controller";
import { SquareCheckoutFormController } from "./payment/square_checkout_form_controller";

application.register("checkout-form", CheckoutFormController);
application.register("date-picker-form", DatePickerFormController);
application.register("lead-scan-grid", LeadScanGridController);
application.register("read-only-grid", ReadOnlyGridController);
application.register("reg-form", RegFormController);
application.register("rooming-field", RoomingFieldController);
application.register("square-checkout-form", SquareCheckoutFormController);
