import React, { FC } from "react";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import railsFetch from "./railsFetch";

export interface SquareCheckoutFormProps {
  squareKey: string;
  amount: number;
  currency: string;
  paymentUrl: string;
}

export interface TransactionResponse {
  location: string;
}
export const SquareCheckoutForm: FC<SquareCheckoutFormProps> = ({
  squareKey,
  paymentUrl,
  amount,
}) => {
  return (
    <PaymentForm
      applicationId={squareKey}
      cardTokenizeResponseReceived={async (tokenResult, verifiedBuyer) => {
        if (tokenResult.status === "OK") {
          const response = await squareTokenHandler(
            tokenResult.token,
            paymentUrl
          );
          (window as Window).location = response.location;
        }
      }}
      locationId="main"
    >
      <CreditCard
          buttonProps={{
            css: {
              backgroundColor: "#1e8449",
              display: "block",
              fontWeight: "700",
              textTransform: "uppercase",
              padding: "32px",
              fontSize: "16px",
              color: "white",
              textAlign: "center",
              letterSpacing: "0.05em",
              minWidth: "100%",
              "&:hover": {
                backgroundColor: "#31714c",
              },
            },
          }}
      />
    </PaymentForm>
  );
};

async function squareTokenHandler(
  token: string,
  url: string
): Promise<TransactionResponse> {
  const response = await railsFetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ token }),
  });

  return (await response.json()) as TransactionResponse;
}
